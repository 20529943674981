import Component from '@ember/component';
import { A } from '@ember/array';
import { set, computed, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

import { treatmentsValidation } from 'additive-voucher/validations/order';

/**
 *  The dialog that can be used to select the treatments of the voucher. The treatments can be filtered
 *  using the category dropdown at the top.
 *  Since the selected treatments should be returned as an array, they are internally stored as such and
 *  therefore no changeset is used.
 *
 * @class new-order-dialog/treatments-select-dialog
 */
export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  store: service(),
  uiAppSettings: service(),
  uiDialog: service(),

  /**
   * the order changeset that is passed by the HOC
   *
   * @property orderChangeset
   * @type {Changeset}
   * @default null
   */
  orderChangeset: null,

  /**
   * the treatment categories that can be used to filter
   *
   * @property _categories
   * @type {[Object]}
   * @default undefined
   */
  _categories: undefined,

  /**
   * whether the discard changes dialog is shown
   *
   * @property _isDiscardChangesDialog
   * @type {Boolean}
   * @default false
   * @private
   */
  _isDiscardChangesDialog: false,

  /**
   * whether the form has been touched
   *
   * @property _isTouched
   * @type {Boolean}
   * @default false
   * @private
   */
  _isTouched: false,

  /**
   * show error if no count is set
   *
   * @property _isNoCountError
   * @type {Boolean}
   * @default false
   * @private
   */
  _isNoCountError: false,

  /**
   * the selected the category used to filter the treatments
   *
   * @property _selectedCategory
   * @type {Object}
   * @default null
   * @private
   */
  _selectedCategory: null,

  /**
   * the treatments changeset
   *
   * @property _changeset
   * @type {[Object]}
   * @default undefined
   * @private
   */
  _changeset: undefined,

  /**
   * whether the treatments fetch task is running
   *
   * @computed _isLoading
   * @type {Boolean}
   */
  _isLoading: computed.alias('fetchTreatments.isRunning'),

  /**
   * the sum of all the selected treatment prices multiplied by their count
   *
   * @computed _totalAmount
   * @type {Number}
   */
  _totalAmount: computed('_changeset.treatments.@each.count', {
    get() {
      if (this._changeset) {
        return this._changeset
          .get('treatments')
          .reduce((sum, treatment) => sum + (treatment.count || 0) * treatment.price, 0);
      }
      return 0;
    }
  }),

  /**
   * the filtered treatments that are shown in the dialog
   *
   * @computed _filteredTreatments
   * @type {[Object]}
   */
  _filteredTreatments: computed('_changeset.treatments.[]', '_selectedCategory.id', {
    get() {
      if (this._selectedCategory && this._selectedCategory.id !== 'all') {
        return this._changeset
          .get('treatments')
          .filter((treatment) => treatment.category === this._selectedCategory.id);
      }

      return this._changeset && this._changeset.get('treatments');
    }
  }),

  /**
   * Called when the user clicks on the close icon
   *
   * @function onClose
   */
  onClose() {},

  /**
   * Called when valid form is submitted
   *
   * @function onSubmit
   * @param {[Object]} treatments
   */
  onSubmit() {},

  /**
   * fetches the available treatments of the voucher
   *
   * @type {Task}
   * @function fetchTreatments
   */
  fetchTreatments: task(function* () {
    const voucherId = this.orderChangeset && this.orderChangeset.get('voucher.id');
    if (voucherId) {
      const adapter = this.store.adapterFor('voucher');
      const voucherUrl = adapter.buildURL('voucher', voucherId);
      const language = this.orderChangeset.get('language') || 'de';

      const response = yield this.authenticatedFetch.fetch(`${voucherUrl}/treatments`, {
        headers: {
          'Accept-Language': language
        }
      });

      const json = yield response.json();

      // only needed properties are kept and count is added
      const treatments =
        json &&
        json.treatments.map((treatment) => {
          return {
            id: treatment.id,
            title: treatment.title,
            price: treatment.price,
            duration: treatment.duration,
            category: treatment.treatmentCategory,
            count: null
          };
        });

      // set the count properties in case there are already preselected treatments
      const initialTreatments = this.orderChangeset.get('voucher.treatments');
      if (initialTreatments) {
        initialTreatments.forEach((initialTreatment) => {
          const treatment = treatments.find((treatment) => treatment.id === initialTreatment.id);
          treatment && set(treatment, 'count', initialTreatment.count);
        });
      }

      // initialiaze changeset with all available treatments
      const validation = treatmentsValidation(this.intl);
      const changeset = new Changeset({ treatments }, lookupValidator(validation), validation);
      set(this, '_changeset', changeset);
    }
  }).on('didInsertElement'),

  /**
   * fetches the categories of the treatments
   *
   * @type {Task}
   * @function fetchCategories
   */
  fetchCategories: task(function* () {
    const voucherTreatmentCategoryIds =
      this.orderChangeset && this.orderChangeset.get('voucher.treatmentCategories');
    if (voucherTreatmentCategoryIds) {
      // fetch all treatment categories
      const treatmentCategories = yield this.store.findAll('treatment-category');

      // filter categories to just include categories of current voucher
      const voucherTreatmentCategories = (
        (treatmentCategories && A(treatmentCategories)) ||
        []
      ).filter((category) => voucherTreatmentCategoryIds.indexOf(category.id) >= 0);

      // add "all" option
      const selectedCategory = { title: this.intl.t('global.all'), id: 'all' };
      const categories = [selectedCategory].concat(voucherTreatmentCategories);
      setProperties(this, { _categories: categories, _selectedCategory: selectedCategory });
    }
  }).on('didInsertElement'),

  /**
   * validates the selected treatments and invokes the callback
   *
   * @type {Task}
   * @function submitTreatments
   */
  submitTreatments: task(function* () {
    set(this, '_isTouched', true);

    yield this._changeset.validate();

    if (this._changeset.get('isInvalid')) {
      return;
    }

    const treatments = this._changeset
      .get('treatments')
      .filter((treatment) => treatment.count && treatment.count > 0);

    if (treatments && treatments.length > 0) {
      this.onSubmit(treatments);
      this.onClose();
    } else {
      // if no count is set, set error
      set(this, '_isNoCountError', true);
    }
  }),

  actions: {
    /**
     * closes the dialog
     *
     * @function onClose
     */
    onClose() {
      if (this._changeset.get('isDirty')) {
        set(this, '_isDiscardChangesDialog', true);
      } else {
        this.onClose();
      }
    },

    /**
     * passes the validated treatments to the callback
     *
     * @function onSubmit
     */
    onSubmit() {
      this.submitTreatments.perform();
    },

    /**
     *  resets the count error
     *
     * @onInputChange
     */
    onInputChange() {
      set(this, '_isNoCountError', false);
    }
  }
});
