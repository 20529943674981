import { equal } from '@ember/object/computed';
import Component from '@ember/component';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import {
  customVoucherValidation,
  EXISTING_VALIDITY_PERIODS,
  CUSTOM_VALIDITY_PERIOD
} from 'additive-voucher/validations/order';

import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

const MODEL_DEFAULTS = {
  amount: 100,
  validityPeriod: EXISTING_VALIDITY_PERIODS[0]
};

/**
 * Dialog-Component for creating a dynamic-voucher.
 *
 * Dynamic-voucher needs a validity period, amount, name and a descprition/services.
 *
 * Validity period has predefined values or can be custom.
 * When custom we give the user a additional datepicker-field to define the expiry date.
 *
 * @class new-order-dialog/dynamic-voucher-dialog
 */
export default Component.extend({
  tagName: '',

  intl: service(),

  changeset: null,
  orderChangeset: null,
  submitTouched: false,
  isDiscardChangesDialog: false,

  /**
   * options array for the validity periods
   *
   * @property _validityPeriods
   * @type {Array}
   * @default null
   * @private
   */
  _validityPeriods: null,

  isCustomValidityPeriod: equal('changeset.validityPeriod', CUSTOM_VALIDITY_PERIOD),

  todayFormatted: computed({
    get() {
      const now = new Date();
      // might change after using datepicker
      // Add trailing 0 to allways has 2 digits
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      return `${now.getFullYear()}-${month}-${day}`;
    }
  }),

  init() {
    this._super(...arguments);

    set(
      this,
      '_validityPeriods',
      EXISTING_VALIDITY_PERIODS.map((validityPeriod) => {
        return {
          value: validityPeriod,
          name: this.intl.t(`vouchers.expirationFormats.${validityPeriod}`)
        };
      })
    );

    const orderChangeset = this.orderChangeset;
    const intl = this.intl;
    const voucher = orderChangeset && orderChangeset.get('voucher');

    const customVoucherModel = {
      amount: (voucher && voucher.amount) || `${MODEL_DEFAULTS.amount}`,
      name: (voucher && voucher.name) || '',
      services: (voucher && voucher.services) || '',
      validityPeriod: (voucher && voucher.validityPeriod) || MODEL_DEFAULTS.validityPeriod,
      validityPeriodCustomDate: (voucher && voucher.validityPeriodCustomDate) || null,
      /* This is needed as the ED-adapter uses this to decide to which endpoint needs to be send  */
      type: 'dynamic'
    };

    const validation = customVoucherValidation(intl);
    const changeset = new Changeset(customVoucherModel, lookupValidator(validation), validation);

    set(this, 'changeset', changeset);
  },

  onClose() {},
  onSubmit() {},

  actions: {
    onClose() {
      const changeset = this.changeset;
      if (changeset.get('isDirty')) {
        set(this, 'isDiscardChangesDialog', true);
      } else {
        this.onClose();
      }
    },
    onValidityPeriodChange(newKey) {
      const changeset = this.changeset;
      if (newKey === CUSTOM_VALIDITY_PERIOD) {
        set(changeset, 'validityPeriodCustomDate', this.todayFormatted);
      } else {
        set(changeset, 'validityPeriodCustomDate', null);
      }

      changeset.set('validityPeriod', newKey);
    },
    onSubmit() {
      const changeset = this.changeset;
      changeset.validate().then(() => {
        if (changeset.isValid) {
          this.onSubmit(changeset);
        } else {
          set(this, 'submitTouched', true);
        }
      });
    },
    onDiscard() {
      const changeset = this.changeset;
      changeset && changeset.rollback();

      this.onClose();

      set(this, 'isDiscardChangesDialog', false);
    }
  }
});
