import Component from '@ember/component';

import { task, timeout, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

/**
 * Component for fetching shipping model and output list
 */
export default Component.extend({
  intl: service(),
  router: service(),
  store: service(),
  uiAppSettings: service(),
  uiDialog: service(),
  uiToast: service(),

  readonly: true,
  _shippings: null,

  isLoading: alias('getShipping.isRunning'),
  defaultLanguage: alias('uiAppSettings.languages.defaultLanguage'),

  _listShippings: computed('_shippings.@each.isNew', {
    get() {
      return this._shippings.filterBy('isNew', false);
    }
  }),

  /**
   * Returns the last segment of the current URL.
   * @computed _currentRouteIndex
   * @return {String} The last segment of the current URL.
   */
  _currentRouteIndex: computed('router.currentURL', {
    get() {
      return this.router.currentURL.split('/').pop();
    }
  }),

  getShipping: task(function* () {
    try {
      let tasks = [];
      /**
       * TODO: if defaultLanguage gets serious,
       * on direct entry uiAppSettings.languages.defaultLanguage might not have
       * loaded yet, we have to block that request somehow befor this is working properly
       */
      const request = yield this.store.findAll('shipping', {
        adapterOptions: {
          currentLocale: this.defaultLanguage
        }
      });
      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      set(this, '_shippings', response);
    } catch (error) {
      throw new Error(`[av-shipping-list] fetch shippings ${error}`);
    }
  }),

  init() {
    this._super(...arguments);

    const savedShipping = this.store.peekAll('shipping');
    /* Only fetch when nothing in store */
    if (savedShipping && savedShipping.length > 1) {
      set(this, '_shippings', savedShipping);
    } else {
      set(this, '_shippings', []);

      this.getShipping.perform();
    }
  },

  actions: {
    onListItemClick(item) {
      /* "default" items are non-editable items like "E-Mail", so prevent editing */
      if (item.default) {
        set(this, 'isDefaultEditError', true);
      } else {
        this.router.transitionTo('instance.settings.shipping-package.shipping', item.id);
      }
    },
    toggleEnable(item) {
      if (item.hasDirtyAttributes) {
        throw new Error(
          '[av-shipping-list] Item dirty, aborted saving due to possible invalid model'
        );
      }
      item.toggleProperty('enabled');
      item.save().then(() => {
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      });
    }
  }
});
