import Component from '@ember/component';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';

/**
 *  This component contains the order element that can be clicked to open the
 *  dialog in order to select the treatments that the voucher order should contain
 *
 * @class new-order-dialog/order-element-treatments
 */
export default Component.extend({
  uiAppSettings: service(),

  /**
   * The complete order changeset
   *
   * @property orderChangeset
   * @type {Object}
   */
  orderChangeset: null,

  /**
   * whether the dialog is open
   *
   * @property isTreatmentSelectDialog
   * @type {Boolean}
   * @default false
   */
  isTreatmentSelectDialog: false,

  /**
   * whether the element to select the treatments should be shown
   *
   * @computed isTreatmentVoucher
   * @type {Boolean}
   */
  isTreatmentVoucher: equal('orderChangeset.voucher.type', 'treatment'),

  /**
   * the callback triggered when the treatments are selected
   *
   * @function onTreatmentsSelect
   */
  onTreatmentsSelect() {}
});
