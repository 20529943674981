import Component from '@ember/component';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';

/**
 *  This component contains the order element that can be clicked to open the
 *  dialog in order to select the products that the voucher order should contain
 *
 * @class new-order-dialog/order-element-products
 */
export default Component.extend({
  uiAppSettings: service(),

  /**
   * The complete order changeset
   *
   * @property orderChangeset
   * @type {Object}
   */
  orderChangeset: null,

  /**
   * whether the dialog is open
   *
   * @property isProductSelectDialog
   * @type {Boolean}
   * @default false
   */
  isProductSelectDialog: false,

  /**
   * whether the element to select the products should be shown
   *
   * @computed isProductVoucher
   * @type {Boolean}
   */
  isProductVoucher: equal('orderChangeset.voucher.type', 'product'),

  /**
   * the callback triggered when the products are selected
   *
   * @function onProductsSelect
   */
  onProductsSelect() {}
});
