import Component from '@ember/component';
import { A } from '@ember/array';
import { computed, set, setProperties } from '@ember/object';
import { alias, or, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task, timeout, all } from 'ember-concurrency';
import { packageValidations } from 'additive-voucher/validations/package';
import cloneDeep from 'lodash.clonedeep';
import { htmlSafe } from '@ember/string';

import { next } from '@ember/runloop';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

/**
 * Detail modal component to handle editing/creating of a package-model.
 *
 * It is multilingual and handles "inside"-editing,
 * which means it goes into editmode by property,
 * without any extra-route or something.
 *
 * It handles translations CRUD as well.
 *
 * It goes into creation mode, when packageId equals "new".
 *
 * TODO: as we fetch/update the store model when changing translation
 * the list will use the current/last language of the model for outputing info
 * as well. Maybe prevent this behaviour or refetch defaultLanguage befor back-routing.
 *
 * TODO: create multilang-modal component and use it here
 */
export default Component.extend({
  authenticatedFetch: service(),
  store: service(),
  intl: service(),
  router: service(),
  uiAppSettings: service(),
  uiToast: service(),
  uiDialog: service(),

  tagName: '',

  packageId: null,
  model: null,
  currentLocale: 'de',

  /**
   * Whether it should be in read only mode
   *
   * @type {Boolean} readonly
   * @default false
   */
  readonly: false,

  _changeset: null,
  /* prop to snapshot the changeset after creation */
  _availableLanguagesSnapshot: null,
  _reRenderShippings: false,

  isEdit: false,
  isFormTouched: false,

  languages: alias('uiAppSettings.languages.contentLanguages'),
  defaultLanguage: alias('uiAppSettings.languages.defaultLanguage'),

  isLoading: or('getModel.isRunning', 'deleteTranslation.isRunning'),
  isNew: equal('packageId', 'new'),
  shippingsLoading: or('getShippings.isRunning', 'getModel.isRunning', '_reRenderShippings'),

  /**
   * `ui-lang-select`s `publishedLanguages` property,
   * as we not really have publishing by language,
   * but a whole model `publishing` with the `enabled` prop,
   * we return all existing languages when model is enabled, otherwise empty array
   */
  _publishedLanguages: computed('_changeset.enabled', {
    get() {
      return this._changeset.get('enabled') ? this._changeset.get('availableLanguages') : [];
    }
  }),

  getModel: task(function* () {
    try {
      let model = null;

      if (this.isNew) {
        model = yield this.store.createRecord('package', {
          language: this.defaultLanguage || 'de',
          prices: []
        });
        set(this, 'isEdit', true);
      } else {
        model = yield this.store.findRecord('package', this.packageId, {
          adapterOptions: {
            currentLocale: this.currentLocale
          },
          reload: true
        });
      }

      this.createChangeset(model);
    } catch (error) {
      throw new Error(`[av-package-list] fetch packages ${error}`);
    }
  }).restartable(),

  saveModel: task(function* () {
    try {
      this._changeset.set('price', this._changeset.get('price').toString());

      yield this._changeset.validate();

      if (!this._changeset.isValid) {
        return;
      }

      /* Because of deep-clone we are losing the ember-data reference, so manually pass props */
      this._changeset.execute();
      this._model.setProperties(this._changeset.get('data'));

      const savePromise = this._model.save({
        adapterOptions: {
          currentLocale: this.currentLocale
        }
      });
      savePromise.catch((error) => this._handleSaveError(error));

      let tasks = [];
      tasks.push(savePromise);
      tasks.push(timeout(250));

      const [model] = yield all(tasks);

      set(this, 'isEdit', false);

      if (this.isNew) {
        this.router.replaceWith(this.router.currentRouteName, {
          package_id: model.id
        });
      }

      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (error) {
      throw new Error('[av-package-list] saving package', error);
    }
  }).drop(),

  deleteTranslation: task(function* (language) {
    try {
      const adapter = this.store.adapterFor('package');
      const computedUrl = adapter.buildURL('package', this.packageId);
      let tasks = [];
      tasks.push(
        yield this.authenticatedFetch.fetch(`${computedUrl}/${language}`, {
          method: 'DELETE'
        })
      );
      tasks.push(timeout(250));

      yield all(tasks);

      /**
       * If current language was the same as the user wants to delete,
       * change to first available language
       */
      if (this.currentLocale === language) {
        const remainingLanguages = [...this._model.availableLanguages].filter(
          (lang) => lang !== language
        );
        set(
          this,
          'currentLocale',
          remainingLanguages.length ? remainingLanguages[0] : this.defaultLanguage
        );
      }

      this.getModel.perform();
    } catch (error) {
      throw new Error(`[av-package-list] deleting package translation ${error}`);
    }
  }).drop(),

  getShippings: task(function* () {
    try {
      set(this, '_shippings', []);

      let _shippings = yield this.store.peekAll('shipping');

      if (!_shippings || _shippings.length === 0) {
        _shippings = yield this.store.findAll('shipping');
      }
      set(this, '_shippings', _shippings && A(_shippings));
    } catch (error) {
      throw new Error(`[av-package-list] fetch shippings ${error}`);
    }
  }).drop(),

  createChangeset(model) {
    set(this, '_availableLanguagesSnapshot', cloneDeep(model.availableLanguages));

    const validation = packageValidations(this.intl);
    const changeset = new Changeset(model, lookupValidator(validation), validation);
    set(this, '_changeset', changeset);
    set(this, '_initialShippings', (model.shippings.map((shipping) => shipping.id) || []).sort());

    set(this, '_model', model);
  },

  init() {
    this._super(...arguments);

    if (!this.packageId) {
      throw new Error('[av-package-detail-modal] packageId missing, aborted.');
    }

    let model = this.model;
    if (!model) {
      model = this.store.peekRecord('package', this.packageId);
    }

    if (model) {
      this.createChangeset(model);
    } else {
      this.getModel.perform();
    }

    this.getShippings.perform();

    this.defaultLanguage && set(this, 'currentLocale', this.defaultLanguage);
  },

  // handles onSave errors and shows appropriate error dialog
  _handleSaveError(error) {
    if (error && error.errors && error.errors.length > 0) {
      const shippingErrors = error.errors.filter(
        (_error) => _error.detail && _error.detail === 'shipping_not_exists'
      );

      // if there are shipping errors show appropriate error
      if (shippingErrors.length > 0) {
        const { intl } = this;

        // get selected shippings as array
        const selectedShippings =
          (this._changeset.get('data') &&
            this._changeset.get('data').shippings &&
            this._changeset.get('data').shippings.toArray()) ||
          [];

        // get names of inactive shippings
        const inactiveShippings = shippingErrors.map((shipping) => {
          // API delivers only index position of inactive shippings
          const index = shipping.source && shipping.source.pointer.split('.').pop();
          const invalidShipping = selectedShippings[index];

          return invalidShipping && invalidShipping.name;
        });
        const inactiveShippingsString = inactiveShippings
          .map((inactiveShipping) => `<b>${inactiveShipping}</b>`)
          .join(', ');

        this.uiDialog.showAlert(
          intl.t('components.av-package-detail-modal.errors.inactiveShipping.title'),
          htmlSafe(
            intl.t('components.av-package-detail-modal.errors.inactiveShipping.description', {
              count: shippingErrors.length,
              inactiveShippings: inactiveShippingsString
            })
          )
        );
        return;
      }
    }
    this.uiDialog.showError();
  },

  onClose() {},

  actions: {
    onClose() {
      if (this.isEdit) {
        const csShippings = this._changeset
          .get('shippings')
          .map((shipping) => shipping.id)
          .sort();
        const isShippingsDirty =
          JSON.stringify(csShippings) !== JSON.stringify(this._initialShippings);

        if (this._changeset.isDirty || isShippingsDirty) {
          set(this, 'isDiscardChangesDialog', true);
        } else {
          if (this.isNew) {
            this.router.transitionTo('instance.settings.shipping-package');
            this._model.deleteRecord();
          }

          !this.isDestroying && set(this, 'isEdit', false);
        }
      } else {
        this.onClose();
      }
    },
    onDiscard() {
      if (this.isNew) {
        this.router.transitionTo('instance.settings.shipping-package');
        this._model.deleteRecord();
        return;
      }

      this._changeset.rollback();
      this._changeset.set('availableLanguages', cloneDeep(this._availableLanguagesSnapshot));
      /* Reset shippings to initial value, as arrrays does not rollback properly */
      this._changeset.set(
        'shippings',
        this._initialShippings.map((shipping) => this.store.peekRecord('shipping', shipping))
      );

      /* UI-select does not support updating selected values during runtime, so we have to re-render the component... */
      set(this, '_reRenderShippings', true);
      next(this, () => set(this, '_reRenderShippings', false));

      setProperties(this, {
        isEdit: false,
        isDiscardChangesDialog: false,
        _initialShippings: null
      });
    },
    changeLocale(language) {
      if (this.currentLocale === language) {
        return;
      }

      setProperties(this, {
        isFormTouched: false,
        currentLocale: language
      });

      if (this.isNew) {
        this._changeset.set('language', language);
        return;
      }

      this.getModel.perform();
    },
    onNewLanguage(language) {
      setProperties(this, {
        currentLocale: language,
        isEdit: true,
        isFormTouched: false
      });

      /* Reset multilingual props */
      this._changeset.set('name', '');
      this._changeset.set('description', '');
      this._changeset.set('language', language);
    },
    onShippingChange(shipping, checked) {
      if (checked) {
        this._changeset.get('shippings').pushObject(shipping);
      } else {
        this._changeset.get('shippings').removeObject(shipping);
      }
    },
    async toggleEnable() {
      if (this.isEdit) {
        throw new Error(
          '[av-package-detail-modal] In edit mode not possible, aborted saving due to possible invalid model'
        );
      }
      this._changeset.toggleProperty('enabled');
      await this._changeset.save();

      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    }
  }
});
