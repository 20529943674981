import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert (perform this._preselectOptions)}}>\n  <UiSelect\n    @label={{t \"vouchers.voucher.properties.treatmentCategories\"}}\n    @valueKey=\"id\"\n    @onChange={{this.addCategory}}\n    @objectKey=\"title\"\n    @selected={{this._selected}}\n    @options={{this._options}}\n    @isMultiSelect={{true}}\n    @isReadOnly={{@isReadOnly}}\n    @isTouched={{or this.isTouched false}}\n    @errorMessage={{get this.changeset.error.treatmentCategories \"validation\"}}\n    as |option item|\n  >\n    {{#if this._preselectOptions.isRunning}}\n      <option.left>\n        <UiLoading @classNames=\"justify-center items-center\" />\n      </option.left>\n    {{/if}}\n\n    <option.item @value={{item}}>\n      {{item.title}}\n    </option.item>\n  </UiSelect>\n\n  <div class=\"font-xs white\">\n    {{t \"vouchers.voucher.treatmentCategoryCount\" count=this.count}}\n  </div>\n</div>", {"contents":"<div {{did-insert (perform this._preselectOptions)}}>\n  <UiSelect\n    @label={{t \"vouchers.voucher.properties.treatmentCategories\"}}\n    @valueKey=\"id\"\n    @onChange={{this.addCategory}}\n    @objectKey=\"title\"\n    @selected={{this._selected}}\n    @options={{this._options}}\n    @isMultiSelect={{true}}\n    @isReadOnly={{@isReadOnly}}\n    @isTouched={{or this.isTouched false}}\n    @errorMessage={{get this.changeset.error.treatmentCategories \"validation\"}}\n    as |option item|\n  >\n    {{#if this._preselectOptions.isRunning}}\n      <option.left>\n        <UiLoading @classNames=\"justify-center items-center\" />\n      </option.left>\n    {{/if}}\n\n    <option.item @value={{item}}>\n      {{item.title}}\n    </option.item>\n  </UiSelect>\n\n  <div class=\"font-xs white\">\n    {{t \"vouchers.voucher.treatmentCategoryCount\" count=this.count}}\n  </div>\n</div>","moduleName":"additive-voucher/components/av-forms/treatments.hbs","parseOptions":{"srcName":"additive-voucher/components/av-forms/treatments.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { task } from 'ember-concurrency';
import { arg } from 'ember-arg-types';
import { boolean, object } from 'prop-types';

export default class AvFormsTreatments extends Component {
  @service intl;
  @service store;
  @service uiToast;

  /**
   * Changeset with the treatment category data
   *
   * @argument changeset
   * @type {Object}
   * @default null
   */
  @arg(object)
  changeset = null;

  /**
   * Whether the form is touched
   *
   * @argument isTouched
   * @type {Boolean}
   * @default false
   */
  @arg(boolean)
  @tracked
  isTouched = false;

  /**
   * All available treatment categories
   *
   * @property _allTreatmentCategories
   * @type {Object}
   * @default null
   */
  _allTreatmentCategories = null;

  @tracked
  _options = [];

  @tracked
  _selected = [];

  /**
   * Number of selected treatment categories
   *
   * @computed count
   * @type {Integer}
   * @private
   */
  get count() {
    if (!this._allTreatmentCategories) {
      return 0;
    }

    const treatmentCategories = this.changeset.get('treatmentCategories');
    // Filter valid treatment categories
    const validProdCategories = this._allTreatmentCategories.filter(
      (treatmentCategory) => treatmentCategories.indexOf(treatmentCategory.id) >= 0
    );

    return validProdCategories.length;
  }

  /**
   * finds and preselects options
   *
   * @type {Task}
   * @function _preselectOptions
   */
  @task(function* () {
    const treatmentTypes = yield this.store.findAll('treatment-category');
    this._options = treatmentTypes;
    this._allTreatmentCategories = treatmentTypes;

    const treatmentTypeIds = this.changeset.get('treatmentCategories');
    try {
      if (treatmentTypeIds?.length > 0) {
        this._selected = treatmentTypes.filter((type) => treatmentTypeIds.indexOf(type.id) >= 0);
      }
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
  _preselectOptions;

  @action
  addCategory(category, isAdded) {
    if (isAdded) {
      this.changeset.get('treatmentCategories').pushObject(category.id);
    } else {
      this.changeset.get('treatmentCategories').removeObject(category.id);
    }

    this.changeset.validate();
  }
}
