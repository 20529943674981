import Component from '@ember/component';
import { set, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

import { roomTypeValidation } from 'additive-voucher/validations/order';

/**
 * This dialog is opened when the order-element-rooms is clicked.
 * It handles the selection of the room type as well as the number of overnight says and the personcount
 *
 * If the voucher has a fixed personCount the person count select is disabled and shows the fixed value
 *
 * @class new-order-dialog/room-select-dialog
 */
export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  store: service(),
  uiAppSettings: service(),
  uiDialog: service(),

  /**
   * the order changeset that is passed by the HOC
   *
   * @property orderChangeset
   * @type {Changeset}
   * @default null
   */
  orderChangeset: null,

  /**
   * the internal changeset for the room type
   *
   * @property _changeset
   * @type {Changeset}
   * @default null
   * @private
   */
  _changeset: null,

  /**
   * whether the form has been touched
   *
   * @property _isTouched
   * @type {Boolean}
   * @default false
   * @private
   */
  _isTouched: false,

  /**
   * whether the discard changes dialog is shown
   *
   * @property _isDiscardChangesDialog
   * @type {Boolean}
   * @default false
   * @private
   */
  _isDiscardChangesDialog: false,

  /**
   *  the selection options for the stays
   *
   * @property _numberOfNights
   * @type {[Number]}
   * @default false
   * @private
   */
  _numberOfNights: undefined,

  /**
   * the available room types of the voucher
   *
   * @property _roomTypes
   * @type {[Object]}
   * @default undefined
   * @private
   */
  _roomTypes: undefined,

  /**
   * the fixed person count of the voucher if set
   *
   * @computed fixedPersonCount
   * @type {Number}
   */
  fixedPersonCount: computed.alias('orderChangeset.voucher.personCount'),

  /**
   * whether the roomTypes fetch task is running
   *
   * @computed _isLoading
   * @type {Boolean}
   */
  _isLoading: computed.alias('fetchRooms.isRunning'),

  /**
   * the room types including the computed price
   *
   * @computed _roomTypeOptions
   * @type {[Object]}
   */
  _roomTypeOptions: computed(
    '_changeset.{personCount,stays}',
    '_roomTypes.[]',
    'fixedPersonCount',
    {
      get() {
        const personCount = this._changeset.get('personCount') || this.fixedPersonCount;
        const stays = this._changeset.get('stays');
        return (this._roomTypes || []).map((room) => {
          const price =
            personCount && stays
              ? room.basePrice * stays * (room.priceInformation === 'per_person' ? personCount : 1)
              : room.basePrice;
          const isDisabled = personCount > room.occupancyDefault || personCount < 0;
          return { ...room, price, isDisabled };
        });
      }
    }
  ),

  init() {
    this._super(...arguments);

    // set select options
    set(
      this,
      '_numberOfNights',
      [1, 2, 3, 4, 5, 6, 7].map((number) => {
        return {
          value: number,
          name: number
        };
      })
    );

    // initialize the changeset with data if available
    const voucher = this.orderChangeset.get('voucher');
    const roomType = (voucher && voucher.roomType) || {
      id: null,
      name: null,
      personCount: null,
      stays: null
    };

    // pass fixedPersonCount to check if we need to validate personCount
    const validation = roomTypeValidation(this.intl, this.fixedPersonCount);
    const changeset = new Changeset(roomType, lookupValidator(validation), validation);
    set(this, '_changeset', changeset);
  },

  /**
   * Called when the user clicks on the close icon
   *
   * @function onClose
   */
  onClose() {},

  /**
   * Called when valid form is submitted
   *
   * @function onSubmit
   * @param {Object} roomType
   */
  onSubmit() {},

  /**
   * fetches the available roomTypes of the voucher
   *
   * @type {Task}
   * @function fetchRooms
   */
  fetchRooms: task(function* () {
    const voucherId = this.orderChangeset && this.orderChangeset.get('voucher.id');
    if (voucherId) {
      const adapter = this.store.adapterFor('voucher');
      const voucherUrl = adapter.buildURL('voucher', voucherId);
      const language = this.orderChangeset.get('language') || 'de';

      const response = yield this.authenticatedFetch.fetch(`${voucherUrl}/room-types`, {
        headers: {
          'Accept-Language': language
        }
      });

      if (!response || !response.ok) {
        throw new Error('[ROOM_TYPES] Error in response');
      }

      const json = yield response.json();

      set(this, '_roomTypes', json && json.roomTypes.filter((room) => room.basePrice));
    }
  }).on('didInsertElement'),

  actions: {
    /**
     * closes the dialog
     *
     * @function onSubmit
     */
    onClose() {
      if (this._changeset.isDirty) {
        set(this, '_isDiscardChangesDialog', true);
      } else {
        this.onClose();
      }
    },

    /**
     * passes the validated roomType to the callback
     *
     * @function onSubmit
     */
    onSubmit() {
      set(this, '_isTouched', true);
      this._changeset.validate();

      if (this._changeset.isInvalid) {
        return;
      }

      // find room type with id and set price and name
      const roomType = (this._roomTypeOptions || []).find(
        (room) => room.id === this._changeset.get('id')
      );

      if (!roomType) return;

      this._changeset.set('name', roomType.name);
      this._changeset.set('price', roomType.price);

      const roomTypeChangeset = this._changeset.execute();
      this.onSubmit(roomTypeChangeset && roomTypeChangeset.data);
      this.onClose();
    },

    onPersonCountChange(personCount) {
      this._changeset.set('personCount', personCount);
      const selectedRoom = this._roomTypes.filter((room) => {
        return room.id === this._changeset.get('id');
      });
      if (selectedRoom.firstObject && selectedRoom.firstObject.occupancyDefault < personCount) {
        this._changeset.set('id', null);
      }
    }
  }
});
