import Component from '@ember/component';

import { task, timeout, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

/**
 * Component for fetching package model and output list
 */
export default Component.extend({
  intl: service(),
  store: service(),
  uiAppSettings: service(),
  uiDialog: service(),
  uiToast: service(),
  router: service(),

  readonly: true,
  _packages: null,

  defaultLanguage: alias('uiAppSettings.languages.defaultLanguage'),
  isLoading: alias('getPackages.isRunning'),

  _listPackages: computed('_packages.@each.isNew', {
    get() {
      return this._packages.filterBy('isNew', false);
    }
  }),

  /**
   * Returns the last segment of the current URL.
   * @computed _currentRouteIndex
   * @return {String} The last segment of the current URL.
   */
  _currentRouteIndex: computed('router.currentURL', {
    get() {
      return this.router.currentURL.split('/').pop();
    }
  }),

  getPackages: task(function* () {
    try {
      let tasks = [];
      const request = yield this.store.findAll('package', {
        adapterOptions: {
          currentLocale: this.defaultLanguage
        }
      });
      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      set(this, '_packages', response);
    } catch (error) {
      throw new Error(`[av-package-list] fetch shippings ${error}`);
    }
  }),

  init() {
    this._super(...arguments);

    const storePackages = this.store.peekAll('package');
    /* Only fetch when nothing in store */
    if (storePackages && storePackages.length > 1) {
      set(this, '_packages', storePackages);
    } else {
      set(this, '_packages', []);

      this.getPackages.perform();
    }
  },

  actions: {
    toggleEnable(item) {
      if (item.hasDirtyAttributes) {
        throw new Error(
          '[av-shipping-list] Item dirty, aborted saving due to possible invalid model'
        );
      }
      item.toggleProperty('enabled');
      item.save().then(() => {
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      });
    }
  }
});
