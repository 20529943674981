import Route from '@ember/routing/route';

import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import { expirationFormats } from 'additive-voucher/utils/constants';
import { baseValidation, detailValidation } from 'additive-voucher/validations/voucher';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import {
  showWarningDialogBeforeClose,
  removeWarningDialogBeforeClose
} from '@additive-apps/ui/utils/close-tab-utils';

export default class InstanceVouchersIndexCreate extends Route.extend(FullWidthMixin) {
  @service currentUser;
  @service intl;
  @service multimedia;
  @service router;
  @service uiAppSettings;

  get currentLocale() {
    return this.uiAppSettings?.currentLocale;
  }

  get defaultLanguage() {
    return this.uiAppSettings?.languages?.defaultLanguage;
  }

  renderTemplate() {
    this.render('instance/vouchers/index/create', {
      into: 'application'
    });
  }

  beforeModel() {
    super.beforeModel(...arguments);

    if (this.currentUser.isViewer) {
      return this.router.transitionTo('instance.vouchers.index');
    }
  }

  setupController(controller) {
    super.setupController(...arguments);

    const intl = this.intl;
    const EXPIRATION_FORMATS = expirationFormats(intl);

    if (!controller.vt) {
      this.router.transitionTo('instance.vouchers.index.create', { queryParams: { vt: 'value' } });
    }

    const type = controller.vt;
    const { vt } = controller;

    const validityPeriod = EXPIRATION_FORMATS[0].value;

    const modelRef = this.store.createRecord('voucher', {
      type,
      validityPeriod,
      language: this.currentLocale
    });

    const baseValidator = baseValidation(intl, vt);
    const detailValidator = detailValidation(intl, vt);

    const baseChangeset = new Changeset(modelRef, lookupValidator(baseValidator), baseValidator);
    const detailChangeset = new Changeset(
      modelRef,
      lookupValidator(detailValidator),
      detailValidator
    );

    controller.baseChangeset = baseChangeset;
    controller.detailChangeset = detailChangeset;

    this.multimedia.setSelectionOptions({
      type: 'image',
      maxCount: 10,
      minWidth: 800,
      recommendedWidth: 1200,
      warningMessage: this.intl.t('vouchers.voucher.multimedia.imageWarning')
    });

    showWarningDialogBeforeClose(
      this.router,
      this.router.currentRouteName?.slice(0, -6),
      () => controller.getChangesets()?.isDirty,
      () => set(controller, 'isDiscardChangesDialog', true),
      true
    );
  }

  resetController() {
    super.resetController(...arguments);
    removeWarningDialogBeforeClose(this.router);

    this.uiAppSettings.setLocale(this.defaultLanguage);

    this.multimedia.resetSelectionOptions();
  }
}
