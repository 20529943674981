import ENV from 'additive-voucher/config/environment';

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { arg } from 'ember-arg-types';
import { object } from 'prop-types';

export default class AvOrderVoucherDetail extends Component {
  @service currentUser;

  @arg(object)
  @tracked
  order = null;

  treatmentTableHeaderOptions = [
    { i18n: 'orders.voucher.treatment.count', minWidth: '40px' },
    { i18n: 'orders.voucher.treatment.name', minWidth: '120px' },
    { i18n: 'orders.voucher.treatment.duration', minWidth: '80px' },
    { i18n: 'orders.voucher.treatment.price', minWidth: '80px' }
  ];

  productTableHeaderOptions = [
    { i18n: 'orders.voucher.product.count', minWidth: '40px' },
    { i18n: 'orders.voucher.product.name', minWidth: '120px' },
    { i18n: 'orders.voucher.product.price', minWidth: '80px' }
  ];

  /**
   * Checks whether the order has a discount or not
   *
   * @computed hasDiscount
   * @type {Boolean}
   */
  get hasDiscount() {
    const { amountWithDiscount, amountWithoutDiscount } = this.order;
    return amountWithDiscount !== amountWithoutDiscount;
  }

  /**
   * Checks whether the amount that has to be paid is the same as the actual voucher value.
   * if so, we show only one pricing related field.
   *
   * @computed hasAmountToPay
   * @type {Boolean}
   */
  get hasAmountToPay() {
    const { amountToPay, amountWithDiscount } = this.order;
    return amountToPay !== amountWithDiscount;
  }

  /**
   * True if it's a gift-voucher
   *
   * @computed isGift
   * @type {Boolean}
   */
  get isGift() {
    return this.order?.voucher?.type === 'gift';
  }

  /**
   * True if the voucher is only partially redeemed
   *
   * @computed isGift
   * @type {Boolean}
   */
  get isPartiallyRedeemed() {
    return this.order?.state === 'partial_redeemed';
  }

  /**
   * True if the voucher is buyable, i.e. it's not a gift
   *
   * @computed isBuyable
   * @type {Boolean}
   */
  get isBuyable() {
    return !this.isGift;
  }

  /**
   * The path to the organization in the content app
   * @computed contentPath
   * @return {String} the url to the content-app
   */
  get contentPath() {
    return `${ENV.APP.contentHost}/${this.currentUser?.currentOrganization?.id}`;
  }

  @action
  openContent(typePath, productId) {
    window.open(`${this.contentPath}/${typePath}/${productId}`, '_blank');
  }
}
